import {getUserImageHashedFilenameForUploadingImage, UploadStatus} from '@Utils/image.util';

export interface UploadedImageData {
  hashedFilename: string;
  fileExtension: string;
}

export const uploadingImagesMap: Record<string, UploadedImageData> = {};

export const getImageDataForUploadingUidFromMap = (uploadingUid: string): UploadedImageData | undefined => {
  if (Object.keys(uploadingImagesMap).length > 0 && uploadingUid in uploadingImagesMap) {
    return uploadingImagesMap[uploadingUid];
  }
  return undefined;
};

export const getImageDataForUploadingUidFromServer = async (uploadingUid: string): Promise<UploadedImageData> => {
  const imageData = getImageDataForUploadingUidFromMap(uploadingUid);
  if (imageData) {
    return imageData;
  }

  const getUploadingUid = (): string => {
    return uploadingUid;
  };

  const response = await getUserImageHashedFilenameForUploadingImage(getUploadingUid);
  if (response.status === UploadStatus.SUCCESS) {
    uploadingImagesMap[uploadingUid] = {hashedFilename: response.hashedFilename, fileExtension: response.fileExtension};
    return uploadingImagesMap[uploadingUid];
  }
  throw new Error(`image upload failed for uid: ${uploadingUid}`);
};

import type { MaskingObject} from '@PosterWhiteboard/classes/masking/masking.class';
import {Masking} from '@PosterWhiteboard/classes/masking/masking.class';

export interface MaskingItemObject extends MaskingObject {
  angle: number;
  left: number;
  top: number;
  height: number;
  width: number;
  scaleX: number;
  scaleY: number;
}

export abstract class MaskingItem extends Masking {
  public angle = 0;
  public left = 0;
  public top = 0;
  public height = 0;
  public width = 0;
  public scaleX = 1;
  public scaleY = 1;

  public toObject(): MaskingItemObject {
    return {
      ...super.toObject(),
      angle: this.angle,
      left: this.left,
      top: this.top,
      height: this.height,
      width: this.width,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    };
  }

  /**
   * @param offsetX
   * @param offsetY
   */
  public offsetItem(offsetX: number, offsetY: number): void {
    this.left += offsetX;
    this.top += offsetY;
  }
}

import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {MaskingShape} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import type {MaskingFreehand} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import type {MaskingText} from '@PosterWhiteboard/classes/masking/masking-text.class';
import {rgbToHexString} from '@Utils/color.util';
import {applyFillToFabricObject, drawObjectToCanvas} from '@Utils/fabric.util';
import {loadImageAsync} from '@Utils/image.util';
import type {FabricObject, Group} from '@postermywall/fabricjs-2';
import {FabricImage, filters, StaticCanvas} from '@postermywall/fabricjs-2';

export enum MaskingType {
  SHAPE = 'shape',
  FREEHAND = 'freehand',
  TEXT = 'text',
}

export enum MaskingEffect {
  NONE = 'none',
  COLOR_POP = 'colorpop',
}

export interface MaskingObject {
  type: MaskingType;
  maskEffect: MaskingEffect;
  imageWidth: number;
  imageHeight: number;
  insideMasking: boolean;
}

export interface MaskImageWithFabricObjectOpts {
  insideMasking?: boolean;
  maskEffect?: MaskingEffect;
}

/**
 * This is the base class for masking of items.
 */
export abstract class Masking extends CommonMethods {
  public abstract type: MaskingType;
  public maskEffect: MaskingEffect = MaskingEffect.NONE;
  public insideMasking = false;

  /**
   * width of source image defining this masking data
   */
  public imageWidth = 0;

  /**
   * height of source image defining this masking data
   */
  public imageHeight = 0;

  public toObject(): MaskingObject {
    return {
      type: this.type,
      maskEffect: this.maskEffect,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,
      insideMasking: this.insideMasking,
    };
  }

  public isShape(): this is MaskingShape {
    return this.type === MaskingType.SHAPE;
  }

  public isFreehand(): this is MaskingFreehand {
    return this.type === MaskingType.FREEHAND;
  }

  public isText(): this is MaskingText {
    return this.type === MaskingType.TEXT;
  }

  protected async applyMaskForFabricObject(img: HTMLImageElement, maskingFabricObject: FabricObject | Group): Promise<HTMLImageElement> {
    return applyMaskForFabricObject(img, maskingFabricObject, {
      insideMasking: this.insideMasking,
      maskEffect: this.maskEffect,
    });
  }

  protected abstract offsetItem(offsetX: number, offsetY: number): void;

  public abstract applyMaskingToImage(img: HTMLImageElement): Promise<HTMLImageElement>;
}

export const applyMaskForFabricObject = async (
  img: HTMLImageElement,
  maskingFabricObject: FabricObject | Group,
  {insideMasking = false, maskEffect = MaskingEffect.NONE}: MaskImageWithFabricObjectOpts = {}
): Promise<HTMLImageElement> => {
  const maskingFabricObjectCopy = await maskingFabricObject.clone();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');

  if (!ctx) {
    throw new Error('Failed to intialize canvas');
  }

  c.width = img.width;
  c.height = img.height;
  ctx.drawImage(img, 0, 0);

  applyFillToFabricObject(maskingFabricObjectCopy, rgbToHexString([0, 0, 0], 1));

  ctx.globalCompositeOperation = insideMasking ? 'destination-out' : 'destination-in';
  await drawObjectToCanvas(ctx, maskingFabricObjectCopy);

  const maskedImageObject = await loadImageAsync(c.toDataURL('image/png'));
  if (maskEffect === MaskingEffect.COLOR_POP) {
    return addMaskedImageOnGrayScaleImage(img, maskedImageObject);
  }

  return maskedImageObject;
};

const addMaskedImageOnGrayScaleImage = (imageElement: HTMLImageElement, maskedImageElement: HTMLImageElement): Promise<HTMLImageElement> => {
  // initialize fabric canvas
  return new Promise((resolve, reject) => {
    const canvas = createStaticCanvas(imageElement.width, imageElement.height, {
      hoverCursor: 'pointer',
      selection: true,
      controlsAboveOverlay: true,
      center: true,
      /*
       Devices with high DPI screens upscale images when adding them to the canvas.
       set enableRetinaScaling to false, inorder to ignore this effect
      */
      enableRetinaScaling: false,
    });

    // set background and apply greyscale filter
    const backgroundImage = new FabricImage(imageElement, {
      originX: 'left',
      originY: 'top',
    });
    backgroundImage.filters.push(new filters.Grayscale());

    canvas.backgroundImage = backgroundImage;
    backgroundImage.applyFilters();

    const imageToMask = new FabricImage(maskedImageElement, {
      left: 0,
      top: 0,
    });

    canvas.add(imageToMask);
    canvas.renderAll();

    // save the canvas in png format
    const dataURL = canvas.toDataURL({
      multiplier: 1,
      enableRetinaScaling: false,
    });
    const img = new Image();
    const onImageLoadFail = (): void => {
      reject(new Error('Failed to load image for masking item'));
    };
    img.crossOrigin = 'anonymous';
    img.onload = (): void => {
      resolve(img);
    };

    img.onerror = onImageLoadFail;
    img.onabort = onImageLoadFail;

    img.src = dataURL;
  });
};

const createStaticCanvas = (width: number, height: number, opts: Record<string, any>): StaticCanvas => {
  const c = document.createElement('canvas');
  c.width = width;
  c.height = height;
  return new StaticCanvas(c, opts);
};

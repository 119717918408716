import type * as Fabric from '@postermywall/fabricjs-2';
import type {Point} from '@Utils/math.util';
import {Path} from '@postermywall/fabricjs-2';

/**
 * This library contains common functions to draw smooth curves through N points using Splines.
 * spline is a numeric function that is piecewise-defined by polynomial functions,
 * and which possesses a high degree of smoothness at the places where the polynomial pieces connect (which are known as knots).
 *
 * Go to following links for further details (http://scaledinnovation.com/analytics/splines/aboutSplines.html, http://output.jsbin.com/ApitIxo/2/)
 * @author Ahsan Ejaz <ahsan@250mils.com>
 */

export type SplinePoints = Array<Point>;

interface SplinePoint {
  x: number;
  y: number;
}

/**
 * Value of tension for joining points with straight lines.
 * @type {Number}
 * @constant
 */
const STRAIGHT_PATH_TENSION = 0;

/**
 * Value of tension for joining points with smooth curves.
 * @type {Number}
 * @constant
 */
const CURVE_PATH_TENSION = 0.5;

/**
 * This function takes points and some flags, and do all the necessary calculations to draw the splines through the points.
 */
export const getPath = (pts: SplinePoints, close: boolean, isCurved: boolean): Fabric.Path | undefined => {
  let cps: SplinePoints = []; // there will be two controls points for each middle point
  // save all the control points in an array
  if (pts.length >= 3) {
    for (let i = 0; i < pts.length - 2; i++) {
      cps = cps.concat(getControlPoints(pts[i], pts[i + 1], pts[i + 2], isCurved));
    }
  }
  return drawCurvedOrStraightPaths(cps, pts, close, isCurved);
};

/**
 * This function takes points and their respective control points as inputs and
 * draw the curves or straight lines using bezier and quadratic curves.
 */
const drawCurvedOrStraightPaths = (cps: SplinePoints, pts: SplinePoints, close: boolean, isCurved: boolean): Fabric.Path | undefined => {
  const pointsCount = pts.length; // number of points
  // do not draw path if points are less than 2
  if (pointsCount < 2) {
    return undefined;
  }

  let path = '';

  // draw a straight line if pointsCount is equal to two.
  if (pointsCount === 2) {
    path += `M${pts[0].x},${pts[0].y}L${pts[1].x},${pts[1].y}`;
  }
  // draw quadratic curve for the first two and last two points, for all the middle points connect with Bézier curve
  else {
    let i;
    // quadratic curve from point 0 to 1
    path += `M${pts[0].x},${pts[0].y}Q${cps[0].x},${cps[0].y},${pts[1].x},${pts[1].y}`;

    // Bézier curve for all the middle points
    for (i = 2; i < pointsCount - 1; i += 1) {
      path += `C${cps[2 * (i - 1) - 1].x},${cps[2 * (i - 1) - 1].y},${cps[2 * (i - 1)].x},${cps[2 * (i - 1)].y},${pts[i].x},${pts[i].y}`;
    }
    // quadratic curve for last two points
    path += `Q${cps[2 * (i - 1) - 1].x},${cps[2 * (i - 1) - 1].y},${pts[i].x},${pts[i].y}`;

    // if close flag is true, close the path by joining first and last point using quadratic curve.
    if (close) {
      const cpts = getControlPoints(pts[pts.length - 1], pts[0], pts[1], isCurved);
      path += `Q${cpts[0].x},${cpts[0].y},${pts[0].x},${pts[0].y}`;
    }
  }

  return new Path(path);
};

/**
 * Gets tension value according to the type of mask(straight or curved).
 * @return {number}
 * @private
 */
const getTensionForControlPoints = (isCurved: boolean): number => {
  return isCurved ? CURVE_PATH_TENSION : STRAIGHT_PATH_TENSION;
};

/**
 * Given three points, returns two control points for the second point to draw Bézier curve
 * @param {object} p1  first point
 * @param {object} p2  second point
 * @param {object} p3  third point
 * @param {boolean} isCurved
 * @returns {Array} control points for p2
 * @private
 */
const getControlPoints = (p1: SplinePoint, p2: SplinePoint, p3: SplinePoint, isCurved: boolean) => {
  // difference between the coordinates of p1 and p2
  const v = va(p1, p3);
  const d01 = calculateDistance(p1, p2);
  const d12 = calculateDistance(p2, p3);
  const d012 = d01 + d12;
  const tensionForControlPoints = getTensionForControlPoints(isCurved);
  // calculate control points
  const cpt1 = {
    x: p2.x - (v.x * tensionForControlPoints * d01) / d012,
    y: p2.y - (v.y * tensionForControlPoints * d01) / d012,
  };
  const cpt2 = {
    x: p2.x + (v.x * tensionForControlPoints * d12) / d012,
    y: p2.y + (v.y * tensionForControlPoints * d12) / d012,
  };
  return [cpt1, cpt2];
};

/**
 * given an array of points, return difference between the coordinates of any two points
 */
const va = (p1: SplinePoint, p2: SplinePoint) => {
  return {x: p2.x - p1.x, y: p2.y - p1.y};
};

/**
 * given an array of points, return distance between any two points
 */
const calculateDistance = (p1: SplinePoint, p2: SplinePoint) => {
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
};

import {CommonMethods} from '@PosterWhiteboard/common-methods';

export interface GammaEffectObject {
  isEnabled: boolean;
  red: number;
  green: number;
  blue: number;
}

export const GAMMA_COLOR_EFFECT_MIN = 0.01;
export const GAMMA_COLOR_EFFECT_MAX = 2;

export const GAMMA_EFFECT_DEFAULT_VALUES = 1;

export class GammaEffect extends CommonMethods {
  public isEnabled = false;
  public red = GAMMA_EFFECT_DEFAULT_VALUES;
  public green = GAMMA_EFFECT_DEFAULT_VALUES;
  public blue = GAMMA_EFFECT_DEFAULT_VALUES;

  constructor(opts: Partial<GammaEffectObject> = {}) {
    super();
    this.copyVals(opts);
  }

  public toObject(): GammaEffectObject {
    return {
      isEnabled: this.isEnabled,
      red: this.red,
      green: this.green,
      blue: this.blue,
    };
  }

  public updateFromObject(gammaObject: Partial<GammaEffectObject>): void {
    this.copyVals(gammaObject);
  }
}

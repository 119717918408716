import type {MaskingItemObject} from '@PosterWhiteboard/classes/masking/masking-item.class';
import {MaskingItem} from '@PosterWhiteboard/classes/masking/masking-item.class';
import {MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import {loadFabricObjectForShape} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import type * as Fabric from '@postermywall/fabricjs-2';

export interface MaskingShapeObject extends MaskingItemObject {
  shapeName: string;
}

export class MaskingShape extends MaskingItem {
  public type: MaskingType = MaskingType.SHAPE;
  public shapeName!: string;

  public toObject(): MaskingShapeObject {
    return {
      ...super.toObject(),
      shapeName: this.shapeName,
    };
  }

  public async applyMaskingToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    const fabricObject = await loadFabricObjectForShape(this.shapeName);
    this.fixSize(fabricObject);
    const scaleX = img.width / this.imageWidth;
    const scaleY = img.height / this.imageHeight;
    fabricObject.set({
      angle: this.angle,
      scaleX: this.scaleX * scaleX,
      scaleY: this.scaleY * scaleY,
      left: this.left * scaleX,
      top: this.top * scaleY,
    });
    return this.applyMaskForFabricObject(img, fabricObject);
  }

  /**
   * This patches older version of vectors where width in model was different from the fabric object one. This recalculates scale
   * so that the total scaledWidth remains the same onces we set the model with back to the one fabricObject has
   */
  private fixSize(fabricObject: Fabric.Object | Fabric.Group): void {
    this.scaleX *= this.width / fabricObject.width;
    this.scaleY *= this.height / fabricObject.height;
    this.width = fabricObject.width;
    this.height = fabricObject.height;
  }
}

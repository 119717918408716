import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {loadImageAsync} from '@Utils/image.util';
import {fabricObjectToDataUrl} from '@Utils/fabric.util';
import {FabricImage} from '@postermywall/fabricjs-2';

export interface ItemCropObject {
  cropped: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
}

export interface CropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class ItemCrop extends CommonMethods {
  public cropped = false;
  public x = 0;
  public y = 0;
  public width = 0;
  public height = 0;
  public imageWidth = 0;
  public imageHeight = 0;

  public toObject(): ItemCropObject {
    return {
      cropped: this.cropped,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,
    };
  }

  public getCroppedWidthScaleToOriginal(): number {
    return this.cropped ? this.imageWidth / this.width : 1;
  }

  public getCroppedHeightScaleToOriginal(): number {
    return this.cropped ? this.imageHeight / this.height : 1;
  }

  public async applyCropToImage(imageElement: HTMLImageElement): Promise<HTMLImageElement> {
    if (!this.cropped) {
      return imageElement;
    }
    const fabricItem = new FabricImage(imageElement, {});
    const cropData = this.getScaledCropData(imageElement);
    return loadImageAsync(
      fabricObjectToDataUrl(fabricItem, {
        left: cropData.x,
        top: cropData.y,
        width: cropData.width,
        height: cropData.height,
      })
    );
  }

  public isCropped(): boolean {
    return this.cropped;
  }

  public getScaledCropData(img: HTMLImageElement): CropData {
    const scaleXDelta = img.width / this.imageWidth;
    const scaleYDelta = img.height / this.imageHeight;

    return {
      x: Math.round(this.x * scaleXDelta),
      y: Math.round(this.y * scaleYDelta),
      width: Math.round(this.width * scaleXDelta),
      height: Math.round(this.height * scaleYDelta),
    };
  }
}
